import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Bookings.css';

const Bookings = () => {
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    axios.get('https://advertisers-clouds-appear-recent.trycloudflare.com/get_all_bookings')
      .then(response => {
        setBookings(response.data.bookings);
      })
      .catch(error => {
        console.error('There was an error fetching the bookings!', error);
      });
  }, []);

  const parseDetails = (details) => {
    try {
      return JSON.parse(details.replace(/'/g, '"'));
    } catch (e) {
      console.error('Error parsing details', e);
      return {};
    }
  };

  return (
    <div className="bookings-container">
      <h1>Bookings</h1>
      <table className="bookings-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>User ID</th>
            <th>Type</th>
            <th>Destination</th>
            <th>Pickup</th>
            <th>Number of People</th>
            <th>Comments</th>
            <th>UUID</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking, index) => {
            const details = parseDetails(booking[3]);
            return (
              <tr key={index}>
                <td>{booking[0]}</td>
                <td>{booking[1]}</td>
                <td>{booking[2]}</td>
                <td>{details.destination}</td>
                <td>{details.pickup}</td>
                <td>{details.num_people}</td>
                <td>{details.comments}</td>
                <td>{booking[4]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Bookings;
